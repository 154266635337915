
























/**
 * Custom Tag Input Component that actually allows me to control which keys can be pressed.
 * 
 * Made because I couldn't allow commas on the buefy component
 * 
 * Doesn't have any of the other bells and whistles - purely for tags for grammar arcade for now
 */

import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ components: { } })
export default class TagInput extends Mixins() {
  @Prop({default: () => {return []}}) value!: string[]
  @Prop({default: ''}) placeholder!: string

  @Watch('value')
  private onValueUpdate () {
    this.tags = [...this.value]
  }

  private tags: string[] = []

  public text: string = ''

  private isFocused = false

  mounted () {
    this.tags = [...this.value]
  }

  private focus () {
    const input = this.$refs.input as HTMLElement
    input.focus()
    this.isFocused = true
  }

  private onBlur () {
    this.isFocused = false
    if (this.text.length) {
      this.addTag()
    }
  }

  private onKeyDown (e: KeyboardEvent) {
    if (e.key === 'Backspace' && !this.text.length) {
      this.deleteLastTag()
    }
    if (e.key === 'Enter' && this.text.length) {
      this.addTag()
    }
  }

  private addTag () {
    this.tags.push(this.text)
    this.text = ''
    this.updateProp()
  }

  private deleteLastTag () {
    if (this.tags.length) {
      this.deleteTag(this.tags.length - 1)
    }
  }

  private deleteTag (index: number) {
    if (index < this.tags.length) {
      this.tags.splice(index, 1)
      this.updateProp()
    }
  } 

  private updateProp () {
    this.$emit('input', this.tags)
  }

}
