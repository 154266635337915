
import { Component, Mixins } from 'vue-property-decorator'
import { uniq, cloneDeep } from 'lodash'
import { ISO8601Date, MixedContent, PagedResults } from '../api'
import { GrammarBracketGameResponseInfo, GrammarDogGameResponseInfo, GrammarDogGameSessionInfo, GrammarDreamGameResponseInfo, GrammarFishGameResponseInfo, GrammarFishGameSessionInfo, GrammarGameQuestionParams, GrammarGameSessionInfo, GrammarGamesFishGameQuestionResponseData, GrammarGamesQuestionResponseData, GrammarGameType, GrammarHippoGameResponseInfo, GrammarHippoGameSessionInfo, GrammarMazeGameResponseInfo, GrammarMazeGameSessionInfo, GrammarPairGameResponseInfo, GrammarPirateGameResponseInfo, GrammarPunctuationAnswer, GrammarPunctuationQuestionParams, GrammarSentenceQuestionParams, GrammarSentenceQuestionSection, PunctuationMark } from '../api/types/grammar/grammar'
import { GrammarGameIdent, grammarGames } from '../api/types/grammar/grammarGames'
import ModalMixin from '../mixins/ModalMixin'

@Component
export default class GrammarUtils extends Mixins(ModalMixin) {
  protected bonusTimerDefault = 25000

  protected getScore (bonusTimer: number, customMaxTime?: number, customScoreValue?: number) {
    const max = customMaxTime || this.bonusTimerDefault
    const scoreValue = customScoreValue || 1000
    return Math.max(0, Math.round(scoreValue + (Math.min(1, bonusTimer / max) * scoreValue)))
  }

  protected sanitisePunctuationMarks (t: string): string {
    if (t && t !== '' && typeof t === 'string') {
      return t.replace(/(^|\s|[*^#]|[*]\S)(")(\S)/g, (match, symbol, quote, char) => { return `${symbol}“${char}` }).replace(/"/g, '”').replace(/'/, '’').replace(/`/, '’').replace(/ - /, '—').trim()
    }
    return ''
  }

  protected getGrammarGameTypes (): string[] {
    return uniq(grammarGames.map(x => x.type))
  }

  protected verifyPunctuationMark (char: string) {
    return [',', '.', "'", '’', '(', ')', '-', '—', '!', '?', '"', '“', '”', ';', ':'].includes(char)
  }

  protected charToPuncutationMark (char: string): PunctuationMark {
    switch (char) {
      case ',': return 'comma'
      case '.': return 'period'
      case "'": return 'apostrophe'
      case '`': return 'apostrophe'
      case '’': return 'apostrophe'
      case ')': return 'bracket-close'
      case '(': return 'bracket-open'
      case '-': return 'dash'
      case '—': return 'dash'
      case '!': return 'exclamation'
      case '?': return 'question'
      case '"': return 'quote'
      case '“': return 'quote-open'
      case '”': return 'quote-close'
      case ';': return 'semicolon'
      case ':': return 'colon'
      default: return 'comma'
    }
  }

  protected punctuationMarkToChar (type: PunctuationMark): string {
    switch (type) {
      case 'comma' : return ','
      case 'period' : return '.'
      case 'apostrophe' : return '’'
      case 'bracket-close' : return ')'
      case 'bracket-open' : return '('
      case 'dash' : return '—'
      case 'exclamation' : return '!'
      case 'question' : return '?'
      case 'quote' : return '"'
      case 'quote-open' : return '“'
      case 'quote-close' : return '”'
      case 'semicolon' : return ';'
      case 'colon' : return ':'
      default: return ' '
    }
  }

  protected punctuationMarkToAlias (type: PunctuationMark) {
    switch (type) {
      case 'period': return this.$i18n.locale === 'en-us' ? 'period' : 'full stop'
      case 'bracket-close': return 'closing bracket'
      case 'bracket-open': return 'opening bracket'
      case 'exclamation': return 'exclamation mark'
      case 'question': return 'question mark'
      case 'quote': return 'quotation mark'
      case 'quote-open': return 'opening quotation mark'
      case 'quote-close': return 'closing quotation mark'
      case 'semicolon': return 'semicolon'
      case 'colon': return 'colon'
      default: return type
    }
  }

  protected punctuationMarkLinePosition (type: PunctuationMark): 'top' | 'center' | 'bottom' {
    switch (type) {
      case 'comma': return 'bottom'
      case 'period': return 'bottom'
      case 'apostrophe': return 'top'
      case 'bracket-close': return 'center'
      case 'bracket-open': return 'center'
      case 'dash': return 'center'
      case 'exclamation': return 'center'
      case 'question': return 'center'
      case 'quote': return 'top'
      case 'quote-open': return 'top'
      case 'quote-close': return 'top'
      case 'semicolon': return 'center'
      case 'colon': return 'center'
      default: return 'center'
    }
  }

  protected getQuestionTypeTitle (type: GrammarGameType) {
    switch (type) {
      case 'multi': return 'Multiple Choice'
      case 'bracket': return 'Brackets'
      case 'match': return 'Matching'
      case 'punctuation': return 'Punctuation'
      case 'sentence': return 'Sentences'
      default: return 'Grammar Game'
    }
  }

  protected generateDummyQuestionWrapper (gameIdent: GrammarGameIdent, params: GrammarGameQuestionParams) {
    return {
      id: -1,
      version_id: -1,
      node_id: -1,
      created: new Date().toISOString() as ISO8601Date,
      updated: new Date().toISOString() as ISO8601Date,
      params,
      game_ident: gameIdent
      // fx
    }
  }

  protected getCurrentTime () {
    return new Date().toISOString() as ISO8601Date
  }

  protected preparePunctuationQuestion (question: GrammarPunctuationQuestionParams) {
    const toDisplay: string[][] = []
    const correctResult: string[][] = []
    const correctResultAnswerPositions: { word: number, character: number }[] = []
    const answers: GrammarPunctuationAnswer[] = []
    const text = this.sanitisePunctuationMarks(question.text)
    const split = text.split('')
    let word: string [] = []
    let correctWord: string [] = []
    for (let i = 0; i < split.length; i++) {
      const char = split[i]
      if (char === '#') {
        if (split[i + 1] === '\\') {
          // If the input uses escape characters for some reason.
          answers.push({
            method: 'insert',
            type: this.charToPuncutationMark(split[i + 2]),
            string: split[i + 2]
          })
          correctResultAnswerPositions.push({ word: correctResult.length, character: correctWord.length })
          correctWord.push(split[i + 2])
          i += 2
        } else {
          const charType = this.charToPuncutationMark(split[i + 1])
          answers.push({
            method: 'insert',
            type: charType,
            string: split[i + 1]
          })
          correctResultAnswerPositions.push({ word: correctResult.length, character: correctWord.length })
          if (charType === 'dash' && !word.length) {
            // Em Dashes are surrounded by spaces so we don't treat them as a word.
            correctWord.push('—')
            correctWord.push(' ')
            i += 2 // skip the following space
          } else {
            // All other characters go at the start or end of a word
            correctWord.push(split[i + 1])
            i += 1
          }
        }
      } else if (char === '^') {
        if (split[i + 1].match(/[A-Z]/)) {
          answers.push({
            method: 'replace',
            type: 'capital',
            string: ''
          })
          word.push(split[i + 1].toLowerCase())
          correctResultAnswerPositions.push({ word: correctResult.length, character: correctWord.length })
          correctWord.push(split[i + 1])
        } else if (split[i + 1].match(/[a-z]/)) {
          answers.push({
            method: 'replace',
            type: 'lowercase',
            string: ''
          })
          word.push(split[i + 1].toUpperCase())
          correctResultAnswerPositions.push({ word: correctResult.length, character: correctWord.length })
          correctWord.push(split[i + 1])
        }
        i += 1
      } else if (char === '*') {
        if (split[i + 1] === '\\') {
          answers.push({
            method: 'replace',
            type: this.charToPuncutationMark(split[i + 2]),
            string: split[i + 2]
          })
          word.push(split[i + 3])
          correctResultAnswerPositions.push({ word: correctResult.length, character: correctWord.length - 1 })
          correctWord.push(split[i + 2])
          i += 3
        } else {
          const charType = this.charToPuncutationMark(split[i + 1])

          answers.push({
            method: 'replace',
            type: this.charToPuncutationMark(split[i + 1]),
            string: split[i + 1]
          })
          if (charType === 'dash' && !word.length) {
            // If is Em Dash
            correctWord.push('—')
          } else {
            correctWord.push(split[i + 1])
          }
          word.push(split[i + 2])
          correctResultAnswerPositions.push({ word: correctResult.length, character: correctWord.length - 1 })
        }

        i += 2
      } else if (char === ' ') {
        word.push(' ')
        correctWord.push(' ')
        toDisplay.push(word)
        correctResult.push(correctWord)
        word = []
        correctWord = []
      } else {
        word.push(char)
        correctWord.push(char)
      }
    }
    if (word.length) {
      toDisplay.push(word)
    }
    if (correctWord.length) {
      correctResult.push(correctWord)
    }
    return { answers, toDisplay, correctResult, correctResultAnswerPositions }
  }

  protected prepareSentenceQuestion (question: GrammarSentenceQuestionParams) {
    const split = question.text.split(' ')
    const currentSection: GrammarSentenceQuestionSection = {
      autoFill: false,
      parts: []
    }
    let currentString: string[] = []
    const sections: GrammarSentenceQuestionSection[] = []
    for (let i = 0; i < split.length; i++) {
      const p = split[i]
      if (p[0] === '{' && p[p.length - 1] === '}') {
        // Is a single word autofill section
        if (currentSection.parts.length) {
          // close existing section
          sections.push(Object.assign({}, currentSection))
          currentSection.autoFill = false
          currentSection.parts = []
        }
        currentSection.autoFill = true
        currentSection.parts.push(p.replace('{', '').replace('}', ''))
        sections.push(Object.assign({}, currentSection))
        currentSection.autoFill = false
        currentSection.parts = []
      } else if (p[0] === '{') {
        // Is the start of an autofill section
        if (currentSection.parts.length) {
          // close existing section
          sections.push(Object.assign({}, currentSection))
          currentSection.autoFill = false
          currentSection.parts = []
        }
        currentSection.autoFill = true
        currentSection.parts.push(p.replace('{', ''))
      } else if (p[p.length - 1] === '}') {
        // Is the end of an autofill section
        currentSection.parts.push(p.replace('}', ''))
        sections.push(Object.assign({}, currentSection))
        currentSection.autoFill = false
        currentSection.parts = []
      } else if (p[0] === '[' && p[p.length - 1] === ']') {
        currentString.push(p.replace('[', '').replace(']', ''))
        currentSection.parts.push(currentString.join(' '))
        currentString = []
      } else if (p[0] === '[') {
        // Is the start of a grouped answer
        currentString.push(p.replace('[', ''))
      } else if (p[p.length - 1] === ']') {
        // Is the end of a grouped answer
        currentString.push(p.replace(']', ''))
        currentSection.parts.push(currentString.join(' '))
        currentString = []
      } else if (!currentString.length) {
        // If its just a word
        if (question.splitPunctuation) {
          // if we need to split punctuation off the word
          let after = ''
          let str = p
          if (['"', '“', '('].includes(str[0])) {
            const char = str[0].replace('"', '“')
            currentSection.parts.push(char)
            str = str.replace(str[0], '')
          }
          if (['"', '”', ')', ',', '.', ':', ';', '!', '?'].includes(str[str.length - 1])) {
            const char = str[str.length - 1].replace('"', '”')
            after = char
            str = str.replace(str[str.length - 1], '')
          }
          currentSection.parts.push(str)
          if (after) {
            currentSection.parts.push(after)
            after = ''
          }
        } else {
          currentSection.parts.push(p)
        }
      } else {
        currentString.push(p)
      }
    }
    if (currentSection.parts.length) {
      sections.push(currentSection)
    }
    return sections
  }

  // protected async updateGrammarGameSession (update: GrammarGameSessionUpdate) {
  //   try {
  //     const session = await Api.updateGrammarGameSession(update)
  //     if (session) {
  //       return session
  //     }
  //   } catch (error: unknown) {
  //     if (error instanceof Error) {
  //       this.alert({ title: error.name, message: error.message, console: error.stack })
  //     }
  //   }
  // }

  protected generateDummyPaginatedGrammarGameSessions (node_id: number, ident?: GrammarGameIdent) {
    const sessions:GrammarGameSessionInfo[] = []

    for (let i = 0; i < 10; i++) {
      sessions.push(this.generateDummyGrammarGameSessionWithResponses(node_id, ident || 'dog'))
    }
    return { items: sessions, total: 10 } as PagedResults<GrammarGameSessionInfo>
  }

  protected generateDummyGrammarGameSessionWithResponses (node_id: number, ident?: GrammarGameIdent) {
    const session: GrammarGameSessionInfo = {
      created: new Date().toISOString() as ISO8601Date,
      earnings: Math.round(Math.random() * 60),
      game_ident: ident || 'dog',
      id: -1,
      node_id,
      user_id: -1,
      user_name: 'dummy',
      responses: []
    }
    if (!ident || ident === 'dog') {
      const responses: GrammarDogGameResponseInfo[] = []
      let bones = 10
      for (let i = 0; i < 10; i++) {
        const dogs = Math.ceil(Math.random() * 3)
        const question = this.generateDummyQuestionWrapper(ident || 'dog', { type: 'multi', title: { type: 'text', value: 'dummy test' }, answers: [], shuffleAnswers: true })
        question.id = i
        for (let j = 0; j < dogs; j++) {
          responses.push({
            created: new Date().toISOString() as ISO8601Date,
            correct: j === 0,
            question,
            score: j === 0 ? 1000 + Math.floor(Math.random() * 1000) : 0,
            params: {
              answer: { correct: j === 0, index: j, text: 'dummy test' },
              bones_wagered: j === 0 ? Math.max(1, bones - (dogs - 1)) : 1
            }
          })
        }
        bones -= (dogs - 1)
        if (bones < 1) { bones = 1 }
      }
      session.responses = responses
      return session
    } else if (ident === 'fish') {
      const responses: GrammarFishGameResponseInfo[] = []
      for (let i = 0; i < 4; i++) {
        const difficulty = Math.ceil(Math.random() * 4)
        for (let j = 0; j < difficulty + 1; j++) {
          const question = this.generateDummyQuestionWrapper(ident, { type: 'multi', title: { type: 'text', value: 'dummy test' }, answers: [], shuffleAnswers: true })
          question.id = j
          responses.push({
            created: new Date().toISOString() as ISO8601Date,
            correct: true,
            question,
            score: 1000 + Math.floor(Math.random() * 1000),
            params: {
              fish_index: i,
              answer: { correct: true, index: j, text: 'dummy test' },
              difficulty,
              fish_caught: j === difficulty
            }
          })
        }
      }
      session.responses = responses
      return session
    } else if (ident === 'hippo') {
      const responses: GrammarHippoGameResponseInfo[] = []
      for (let i = 0; i < 10; i++) {
        const question = this.generateDummyQuestionWrapper(ident, { type: 'multi', title: { type: 'text', value: 'dummy test' }, answers: [], shuffleAnswers: true })
        question.id = i
        responses.push({
          created: new Date().toISOString() as ISO8601Date,
          correct: true,
          question,
          score: 1000 + Math.floor(Math.random() * 1000),
          params: {
            answer: { correct: true, index: 0, text: 'dummy test' },
            streak: i + 1
          }
        })
      }
      session.responses = responses
      return session
    } else if (ident === 'maze') {
      const responses: GrammarMazeGameResponseInfo[] = []
      for (let i = 0; i < 10; i++) {
        const question = this.generateDummyQuestionWrapper(ident, { type: 'multi', title: { type: 'text', value: 'dummy test' }, answers: [], shuffleAnswers: true })
        question.id = i
        responses.push({
          created: new Date().toISOString() as ISO8601Date,
          correct: true,
          question,
          score: 1000 + Math.floor(Math.random() * 1000),
          params: {
            answer: { correct: true, index: 0, text: 'dummy test' },
            difficulty: 1,
            lives_remaining: 3
          }
        })
      }
      session.responses = responses
      return session
    } else if (ident === 'bracket') {
      const responses: GrammarBracketGameResponseInfo[] = []
      for (let i = 0; i < 10; i++) {
        const question = this.generateDummyQuestionWrapper(ident, { type: 'bracket', text: 'test dummy', bracketType: 'comma' })
        question.id = i
        responses.push({
          created: new Date().toISOString() as ISO8601Date,
          correct: true,
          question,
          score: 1000 + Math.floor(Math.random() * 1000),
          params: {
            answer: 'dummy'
          }
        })
      }
      session.responses = responses
      return session
    } else if (ident === 'dream') {
      const responses: GrammarDreamGameResponseInfo[] = []
      for (let i = 0; i < 10; i++) {
        const question = this.generateDummyQuestionWrapper(ident, { type: 'sentence', text: 'test dummy', falseWords: [], speakAnswer: true, splitPunctuation: false })
        question.id = i
        responses.push({
          created: new Date().toISOString() as ISO8601Date,
          correct: true,
          question,
          score: 1000 + Math.floor(Math.random() * 1000),
          params: {
            answer: 'dummy',
            question: 'dummy'
          }
        })
      }
      session.responses = responses
      return session
    } else if (ident === 'pair') {
      const responses: GrammarPairGameResponseInfo[] = []
      const question = this.generateDummyQuestionWrapper(ident, { type: 'match', title: { type: 'text', value: 'test dummy' }, items: [] })
      for (let i = 0; i < 10; i++) {
        responses.push({
          created: new Date().toISOString() as ISO8601Date,
          correct: true,
          question,
          score: 1000 + Math.floor(Math.random() * 1000),
          params: {
            items: [{ id: 0, matches: ['north', 'south'] }, { id: 0, matches: ['north', 'south'] }],
            matches: ['north', 'south']
          }
        })
      }
      session.responses = responses
      return session
    } else if (ident === 'pirates') {
      const responses: GrammarPirateGameResponseInfo[] = []
      for (let i = 0; i < 10; i++) {
        const question = this.generateDummyQuestionWrapper(ident, { type: 'punctuation', text: 'dummy question' })
        question.id = i
        responses.push({
          created: new Date().toISOString() as ISO8601Date,
          correct: true,
          question,
          score: 1000 + Math.floor(Math.random() * 1000),
          params: {
            question: 'dummy question',
            answer: 'dummy question'
          }
        })
      }
      session.responses = responses
      return session
    }
    return session
  }

  protected getBonesRemainingForDogGameSession (session: GrammarDogGameSessionInfo) {
    const lastQuestion = session.responses[session.responses.length - 1].question
    const lastResponses = session.responses.filter(x => x.question.id === lastQuestion.id)

    const correctResponse = lastResponses.find(x => x.correct)
    if (correctResponse) {
      return correctResponse.params.bones_wagered
    }
    return 0
  }

  protected getLivesRemainingForMazeGameSession (session: GrammarMazeGameSessionInfo) {
    const response = session.responses[session.responses.length - 1]
    if (response) {
      return response.params.lives_remaining
    }
    return 0
  }

  protected getDifficultyFromMazeGameSession (session: GrammarMazeGameSessionInfo) {
    const response = session.responses[0]
    if (response) {
      switch (response.params.difficulty) {
        case 1: return 'Easy'
        case 2: return 'Normal'
        case 3: return 'Hard'
      }
    }
    return 'Unknown'
  }

  protected getMaxStreakForHippoGameSession (session: GrammarHippoGameSessionInfo) {
    return Math.max(0, ...session.responses.map(x => x.params.streak))
  }

  protected getGrammarQuestionResponseDataFromSession (session: GrammarGameSessionInfo): GrammarGamesQuestionResponseData[] {
    if (!session.responses.length) { return [] }
    const response: GrammarGamesQuestionResponseData[] = []
    const currentQuestion: GrammarGamesQuestionResponseData = { question: session.responses[0].question, responses: [] }
    for (let i = 0; i < session.responses.length; i++) {
      const r = session.responses[i]
      if (r.question.id === currentQuestion.question.id) {
        currentQuestion.responses.push(r)
      } else {
        response.push(cloneDeep(currentQuestion))
        currentQuestion.question = r.question
        currentQuestion.responses = [r]
      }
    }
    response.push(cloneDeep(currentQuestion))
    return response
  }

  protected getGrammarFishGameReportingData (session: GrammarFishGameSessionInfo): GrammarGamesFishGameQuestionResponseData[] {
    if (!session.responses.length) { return [] }
    const response: GrammarGamesFishGameQuestionResponseData[] = []
    let fishIndex = session.responses[0].params.fish_index
    const currentFish: GrammarGamesFishGameQuestionResponseData = { difficulty: session.responses[0].params.difficulty, responses: [] }
    for (let i = 0; i < session.responses.length; i++) {
      const r = session.responses[i]
      if (r.params.fish_index === fishIndex) {
        currentFish.responses.push(r)
      } else {
        response.push(cloneDeep(currentFish))
        currentFish.difficulty = r.params.difficulty
        currentFish.responses = [r]
        fishIndex = r.params.fish_index
      }
    }
    response.push(cloneDeep(currentFish))

    return response
  }
}
