



























































































































































































































































































































































































































































































































































import _ from 'lodash'

import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import StringConversions from '@/mixins/StringConversions'
import { Api } from '@/edshed-common/api'
import { GrammarStage, GrammarSubject, GrammarTopic, ISO8601Date, PagedResults, QuizSubjectInfo, TableQuery, TableState } from '@/edshed-common/api/types'
import Modal from '@/edshed-common/components/Modal.vue'
import GrammarNodeSelector from '@/edshed-common/components/grammar/GrammarNodeSelector.vue'
import TagInput from '@/edshed-common/components/grammar/TagInput.vue'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import GrammarUtils from '@/edshed-common/utils/grammarUtils'
import { GrammarBracketGameQuestionInfo, GrammarBracketQuestionParams, GrammarGameQuestionCreateRequest, GrammarGameQuestionEditRequest, GrammarGameQuestionInfo, GrammarGameType, GrammarMatchItem, GrammarMatchQuestionParams, GrammarMultipleChoiceAnswer, GrammarMultipleChoiceQuestionParams, GrammarPunctuationQuestionParams, GrammarSentenceQuestionParams } from '@/edshed-common/api/types/grammar/grammar'
import { GrammarGameIdent, grammarGames } from '@/edshed-common/api/types/grammar/grammarGames'
import { Dictionary } from 'vue-router/types/router'
import ComponentHelper from '@/mixins/ComponentHelper'

@Component({
  components: {
    Modal,
    GrammarNodeSelector,
    TagInput
  }
})
export default class GrammarArcade extends mixins(ModalMixin, GrammarUtils, ComponentHelper) {
  private selectedGame: GrammarGameIdent = 'dog'
  private filterByGame = false
  private allGames = grammarGames
  private selectedNode: number = -1
  private singleQuestionViewId: number | null = null
  private loading: boolean = true
  private errors: string[] = []
  private tableUpdateKey = 0
  private grammarStages: GrammarStage[] = []
  private results: PagedResults<GrammarGameQuestionInfo> = {
    items: [],
    total: 0
  }

  private tableState: TableState = {
    dir: 'desc',
    sort: 'id',
    page: 1,
    perPage: 10,
    term: ''
  }

  private questionToEdit: GrammarGameQuestionInfo | null = null

  private defaultMultiParams: GrammarMultipleChoiceQuestionParams = {
    title: { type: 'text', value: '' },
    answers: [],
    shuffleAnswers: true,
    type: 'multi'
  }

  private defaultBracketParams: GrammarBracketQuestionParams = {
    text: '',
    bracketType: 'bracket',
    type: 'bracket'
  }

  private defaultPunctuationParams: GrammarPunctuationQuestionParams = {
    text: '',
    type: 'punctuation'
  }

  private defaultMatchParams: GrammarMatchQuestionParams = {
    title: { type: 'text', value: '' },
    items: [],
    type: 'match'
  }

  private defaultSentenceParams: GrammarSentenceQuestionParams = {
    title: { type: 'text', value: '' },
    text: '',
    falseWords: [],
    speakAnswer: false,
    splitPunctuation: false,
    type: 'sentence'
  }

  // Edit Form Variables
  private gameIdent: GrammarGameIdent = 'dog'

  private multiParams: GrammarMultipleChoiceQuestionParams = this.defaultMultiParams
  private multiEnableDescription = false
  private multiCorrectAnswer = 0
  private bracketParams: GrammarBracketQuestionParams = this.defaultBracketParams
  private punctuationParams: GrammarPunctuationQuestionParams = this.defaultPunctuationParams
  private matchParams: GrammarMatchQuestionParams = this.defaultMatchParams
  private matchEnableDescription = false
  private sentenceParams: GrammarSentenceQuestionParams = this.defaultSentenceParams
  private sentenceEnableDescription = false
  private sentenceEnableTitle = true

  private showAllAnswers = false

  mounted () {
    if (this.$route.query) {
      if (this.$route.query.g) {
        this.selectedGame = this.$route.query.g as GrammarGameIdent
      }
      if (this.$route.query.n) {
        this.selectedNode = parseInt(`${this.$route.query.n}`)
      }
      if (this.$route.query.p) {
        this.tableState.page = Math.max(1, parseInt(`${this.$route.query.p}`))
      }
      if (this.$route.query.q) {
        this.singleQuestionViewId = parseInt(`${this.$route.query.q}`)
      }
    }
    this.getLessonData()
    this.getGrammarQuestionsData()
  }

  get nodeHasEnoughQuestions () {
    if (this.selectedNode > -1) {
      if (this.selectedGame === 'pair') {
        if (this.results.total > 0) {
          return true
        }
      }
      return this.results.total >= 10
    }
    return false
  }

  get requiredQuestionsForNode () {
    if (this.selectedNode > -1) {
      if (this.selectedGame === 'pair') {
        if (this.results.total > 0) {
          return 1
        }
      }
      return 10
    }
    return 0
  }

  get selectedGameObject () {
    return this.allGames.find(x => x.ident === this.selectedGame)
  }

  get punctuationQuestionPreview () {
    if (this.punctuationParams && this.punctuationParams.text) {
      const output = this.preparePunctuationQuestion(this.punctuationParams)
      return output.toDisplay.map(x => x.join('')).join('')
    }
    return ''
  }

  get punctuationCorrectPreview () {
    if (this.punctuationParams && this.punctuationParams.text) {
      const output = this.preparePunctuationQuestion(this.punctuationParams)
      return output.correctResult.map(x => x.join('')).join('')
    }
    return ''
  }

  get punctuationAnswersPreview () {
    if (this.punctuationParams && this.punctuationParams.text) {
      const output = this.preparePunctuationQuestion(this.punctuationParams)
      return output.answers
    }
    return []
  }

  get sentenceQuestionSections () {
    const sections = this.prepareSentenceQuestion(this.sentenceParams)
    if (sections.length) {
      return sections
    }
    return []
  }

  get sentenceQuestionsAnswers () {
    const answers: string[] = []
    this.sentenceQuestionSections.forEach((x) => {
      if (!x.autoFill) {
        answers.push(...[...x.parts])
      }
    })
    this.sentenceParams.falseWords.forEach((x) => {
      answers.push(x)
    })
    return this.shuffleArray(answers)
  }

  get sentenceQuestionHasAutofill () {
    return !!this.sentenceQuestionSections.find(x => x.autoFill)
  }

  get currentQuestionParams () {
    if (this.questionToEdit) {
      const game = this.allGames.find(x => x.ident === this.questionToEdit!.game_ident)
      if (game) {
        if (game.type === 'multi') {
          return this.multiParams
        }
        if (game.type === 'punctuation') {
          return this.punctuationParams
        }
        if (game.type === 'match') {
          return this.matchParams
        }
        if (game.type === 'sentence') {
          return this.sentenceParams
        }
        if (game.type === 'bracket') {
          return this.bracketParams
        }
      }
    }
    return this.defaultMultiParams
  }

  get isDetailedGameType () {
    return this.selectedGame !== 'bracket' && this.selectedGame !== 'pirates' && this.selectedGame !== 'dream'
  }

  get lessonsForSelectedGame (): GrammarStage[] {
    if (!this.selectedGame) { return [] }
    return this.grammarStages.reduce((acc: GrammarStage[], stage: GrammarStage) => {
      const filteredSubjects = stage.subjects.reduce((subjAcc: GrammarSubject[], subject: GrammarSubject) => {
        const filteredTopics = subject.topics.reduce((topicAcc: GrammarTopic[], topic: GrammarTopic) => {
          const filteredLessons = topic.lessons.filter(lesson => lesson.games && lesson.games.includes(this.selectedGame))
          if (filteredLessons.length > 0) {
            topicAcc.push({ ...topic, lessons: filteredLessons })
          }
          return topicAcc
        }, [])
        if (filteredTopics.length > 0) {
          subjAcc.push({ ...subject, topics: filteredTopics })
        }
        return subjAcc
      }, [])
      if (filteredSubjects.length > 0) {
        acc.push({ ...stage, subjects: filteredSubjects })
      }
      return acc
    }, [])
  }

  get nodeTreeForSelector () {
    if (this.filterByGame) {
      return this.lessonsForSelectedGame
    }
    return this.grammarStages
  }

  private questonHasMixedContentTitle (type) {
    if (['multi', 'match'].includes(type)) {
      return true
    }
    return false
  }

  private questionHasTextTitle (type) {
    if (['bracket', 'punctuation'].includes(type)) {
      return true
    }
    return false
  }

  private tablePageChanged (page: number) {
    this.tableState.page = Math.max(1, page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getGrammarQuestionsData()
  }

  private viewAllQuestions () {
    this.singleQuestionViewId = null
    this.updateURLQuery()
    this.getGrammarQuestionsData()
  }

  private async getGrammarQuestionsData () {
    if (this.selectedNode === -1 && this.singleQuestionViewId === null) {
      this.loading = false
      return
    }
    try {
      this.loading = true

      let data: PagedResults<GrammarGameQuestionInfo>

      if (this.singleQuestionViewId) {
        try {
          const questionData = await Api.getGrammarQuestion(this.singleQuestionViewId)
          this.selectedNode = questionData.node_id
          this.updateURLQuery()
          data = {
            items: [questionData],
            total: 1
          }
        } catch (error) {
          this.alert({
            title: 'Error',
            message: 'Failed to get individual question data'
          })
          this.singleQuestionViewId = null
          this.updateURLQuery()
          this.getGrammarQuestionsData()
          return
        }
      } else {
        data = await Api.getGrammarQuestions(this.selectedGame, this.selectedNode, {
          dir: this.tableState.dir,
          skip: this.tableState.perPage * (this.tableState.page - 1),
          sort: this.tableState.sort,
          take: this.tableState.perPage,
          term: this.tableState.term
        })
      }

      this.tableUpdateKey++

      if (data) {
        this.results = data
      }
      this.loading = false
    } catch (error: unknown) {
      this.loading = false

      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private resetParams () {
    this.multiParams = _.cloneDeep(this.defaultMultiParams)
    this.multiEnableDescription = false
    this.multiCorrectAnswer = 0
    this.bracketParams = _.cloneDeep(this.defaultBracketParams)
    this.punctuationParams = _.cloneDeep(this.defaultPunctuationParams)
    this.matchParams = _.cloneDeep(this.defaultMatchParams)
    this.matchEnableDescription = false
    this.sentenceParams = _.cloneDeep(this.defaultSentenceParams)
    this.sentenceEnableDescription = false
    this.sentenceEnableTitle = true
    // this.errors = []
  }

  private editQuestion (question: GrammarGameQuestionInfo) {
    this.resetParams()
    this.questionToEdit = _.cloneDeep(question) as GrammarGameQuestionInfo
    this.gameIdent = this.questionToEdit.game_ident
    const game = this.allGames.find(x => x.ident === question.game_ident)
    if (game) {
      if (game.type === 'multi') {
        const params = _.cloneDeep(question.params) as GrammarMultipleChoiceQuestionParams
        this.multiParams = params
        this.multiEnableDescription = !!params.description
        const correctAnswer = params.answers.find(x => x.correct)
        this.multiCorrectAnswer = correctAnswer ? correctAnswer.index : 0
      }
      if (game.type === 'punctuation') {
        const params = _.cloneDeep(question.params) as GrammarPunctuationQuestionParams

        this.punctuationParams = params
      }
      if (game.type === 'match') {
        const params = _.cloneDeep(question.params) as GrammarMatchQuestionParams
        this.matchEnableDescription = !!params.description

        this.matchParams = params
      }
      if (game.type === 'sentence') {
        const params = _.cloneDeep(question.params) as GrammarSentenceQuestionParams
        this.sentenceEnableDescription = !!params.description
        this.sentenceEnableTitle = !!params.title

        this.sentenceParams = params
      }
      if (game.type === 'bracket') {
        const params = _.cloneDeep(question.params) as GrammarBracketQuestionParams

        this.bracketParams = params
      }
    }
  }

  private duplicateQuestion (question: GrammarGameQuestionInfo) {
    this.resetParams()
    const dupeQuestion = _.cloneDeep(question) as GrammarGameQuestionInfo
    dupeQuestion.id = -1
    dupeQuestion.version_id = -1
    dupeQuestion.created = new Date().toISOString() as ISO8601Date
    dupeQuestion.updated = new Date().toISOString() as ISO8601Date
    this.editQuestion(dupeQuestion)
  }

  private previewQuestion (question: GrammarGameQuestionInfo) {
    window.open(`${this.config.targetInfo.game}grammar/game?game=${question.game_ident}&preview_question_id=${question.id}`)
  }

  private newQuestion () {
    const game = grammarGames.find(x => x.ident === this.selectedGame)
    if (game) {
      this.resetParams()
      this.questionToEdit = this.generateDummyQuestionWrapper(game.ident, this.getDefaultParamsForGame(game.ident))
      this.questionToEdit.node_id = this.selectedNode
      this.gameIdent = this.selectedGame
    }
  }

  private async gameIdentChanged () {
    if (!this.questionToEdit) { return }
    if (this.questionToEdit.game_ident !== this.gameIdent) {
      const newGame = this.allGames.find(x => x.ident === this.gameIdent)
      let c = false
      if (newGame && newGame.type !== this.questionToEdit.params.type) {
        c = await this.confirm({ title: 'Warning', message: 'Changing the Game will remove all question data. Are you sure?' })
      }
      if (c) {
        this.questionToEdit.game_ident = this.gameIdent
        this.questionToEdit.params = this.getDefaultParamsForGame(this.gameIdent)
        return
      }
    }
    this.gameIdent = this.questionToEdit.game_ident
  }

  private selectedGameChanged () {
    this.tableState.page = 1
    this.getGrammarQuestionsData()
    this.updateURLQuery()
  }

  private selectedNodeChanged () {
    if (this.selectedNode === null) {
      this.selectedNode = -1
    }
    this.tableState.page = 1
    this.singleQuestionViewId = null
    this.getGrammarQuestionsData()
    this.updateURLQuery()
  }

  private updateURLQuery () {
    const query: Dictionary<string> = { g: this.selectedGame, n: `${this.selectedNode}`, p: `${this.tableState.page}` }
    if (this.singleQuestionViewId) {
      query.q = `${this.singleQuestionViewId}`
    }
    this.$router.replace({ query })
  }

  private punctuationTitle (text:string) {
    const r = this.sanitisePunctuationMarks(text)
    console.log(r)
    return r
  }

  private getMatchExamples (params: GrammarMatchQuestionParams) {
    const examples = [...params.items].slice(0, 3)
    let toWrite = ''
    examples.forEach((x, i) => {
      toWrite += `<p>[${x.matches.join(', ')}]`
      if (i < 2) {
        toWrite += ', </p>'
      } else {
        toWrite += '...</p>'
      }
    })
    return toWrite
  }

  private getDefaultParamsForGame (gameIdent: GrammarGameIdent) {
    const game = grammarGames.find(x => x.ident === gameIdent)
    if (game) {
      const type = game.type
      switch (type) {
        case 'multi': return this.defaultMultiParams
        case 'bracket': return this.defaultBracketParams
        case 'punctuation': return this.defaultPunctuationParams
        case 'sentence': return this.defaultSentenceParams
        case 'match': return this.defaultMatchParams
      }
    }
    return this.defaultMultiParams
  }

  private async deleteQuestion (question: GrammarGameQuestionInfo) {
    const c = await this.deleteConfirm({ name: '' }, { title: 'Delete Question?', message: 'This cannot be undone, continute?' })
    if (!c) {
      return
    }
    if (this.selectedNode > -1 && this.requiredQuestionsForNode === this.results.total) {
      const t = await this.deleteConfirm({ name: '' }, { title: 'Are you sure?', message: 'Deleting this question will disable this node for the selected game because the required number of questions will be below the threshold.' })
      if (!t) {
        return
      }
    }
    try {
      await Api.deleteGrammarQuestion(question.id)
      this.getGrammarQuestionsData()
      this.$buefy.toast.open({
        message: 'Question deleted successfully',
        position: 'is-bottom',
        duration: 4000,
        type: 'is-warning'
      })
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private deleteAnswer (answer: GrammarMultipleChoiceAnswer) {
    this.multiParams.answers = this.multiParams.answers.filter(x => x.index !== answer.index)
    if (answer.correct) {
      this.multiCorrectAnswer = -1
    }
  }

  private deleteMatchItem (item: GrammarMatchItem) {
    this.matchParams.items = this.matchParams.items.filter(x => x.id !== item.id)
  }

  private addAnswer () {
    if (this.multiParams.answers.length < 4) {
      this.multiParams.answers.push({
        correct: false,
        index: this.getNextAvailableAnswerIndex(),
        text: ''
      })
    }
  }

  private addMatchItem () {
    this.matchParams.items.push({
      id: this.getNextAvailableMatchItemIndex(),
      matches: []
    })
  }

  private getNextAvailableAnswerIndex () {
    let n = 0
    const indexes = this.multiParams.answers.map(x => x.index)
    while (indexes.includes(n)) {
      n++
    }
    return n
  }

  private getNextAvailableMatchItemIndex () {
    let n = 0
    const indexes = this.matchParams.items.map(x => x.id)
    while (indexes.includes(n)) {
      n++
    }
    return n
  }

  private async saveQuestion () {
    if (!this.questionToEdit) { return }
    if (this.validateQuestion()) {
      try {
        if (this.questionToEdit.id === -1) {
          // New question
          const payload: GrammarGameQuestionCreateRequest = {
            node_id: this.questionToEdit.node_id,
            game_ident: this.questionToEdit.game_ident,
            params: this.currentQuestionParams
          }
          await Api.createGrammarQuestion(payload)
          await this.getGrammarQuestionsData()
          this.closeEditModal(true)
          this.$buefy.toast.open({
            message: 'Question created successfully',
            position: 'is-bottom',
            duration: 4000,
            type: 'is-success'
          })
        } else {
          // Edit Existing Question
          const payload: GrammarGameQuestionEditRequest = {
            id: this.questionToEdit.id,
            node_id: this.questionToEdit.node_id,
            version_id: this.questionToEdit.version_id,
            params: this.currentQuestionParams
          }
          await Api.editGrammarQuestion(payload)
          await this.getGrammarQuestionsData()
          this.closeEditModal(true)
          this.$buefy.toast.open({
            message: 'Question edited successfully',
            position: 'is-bottom',
            duration: 4000,
            type: 'is-success'
          })
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          this.alert({ title: error.name, message: error.message, console: error.stack })
        }
      }
    } else {
      this.$buefy.toast.open({
        message: 'Could not save question - fix errors and try again.',
        position: 'is-bottom',
        duration: 4000,
        type: 'is-danger'
      })
    }
  }

  private validateQuestion () {
    this.errors = []
    const errors: string[] = []
    if (!this.questionToEdit) { return }
    if (this.questionToEdit.node_id === undefined || this.questionToEdit.node_id === -1) {
      errors.push('node')
    }
    if (['dog', 'fish', 'maze', 'hippo'].includes(this.questionToEdit.game_ident)) {
      errors.push(...this.validateMultiQuestions())
    } else if (['pirates'].includes(this.questionToEdit.game_ident)) {
      errors.push(...this.validatePunctuationQuestion())
    } else if (['bracket'].includes(this.questionToEdit.game_ident)) {
      errors.push(...this.validateBracketQuestion())
    } else if (['pair'].includes(this.questionToEdit.game_ident)) {
      errors.push(...this.validateMatchQuestion())
    } else if (['dream'].includes(this.questionToEdit.game_ident)) {
      errors.push(...this.validateSentenceQuestion())
    }
    this.errors = errors
    return !this.errors.length
  }

  private validateMultiQuestions () {
    const errors: string[] = []
    if (!this.multiParams.title.value) {
      errors.push('title')
    }
    this.multiParams.title.value = this.multiParams.title.value.trim()
    if (this.multiEnableDescription && (!this.multiParams.description || (this.multiParams.description && !this.multiParams.description.value))) {
      this.multiEnableDescription = false
      this.multiParams.description = undefined
    }
    if (!this.multiEnableDescription) {
      this.multiParams.description = undefined
    }
    if (this.multiParams.description) {
      this.multiParams.description.value = this.multiParams.description.value.trim()
    }
    if (this.multiParams.answers.length < 2 || this.multiParams.answers.length > 4) {
      errors.push('answers')
    }
    if (this.multiCorrectAnswer < 0) {
      errors.push('correct')
    }
    if (this.multiParams.answers.filter(x => !x.text).length > 0) {
      errors.push('blankAnswers')
    }
    this.multiParams.answers.forEach((x) => {
      x.correct = this.multiCorrectAnswer === x.index
    })
    return errors
  }

  private validateBracketQuestion () {
    const errors: string[] = []
    if (!this.bracketParams.text) {
      errors.push('blank')
      return errors
    }
    this.bracketParams.text = this.bracketParams.text.trim()
    while (this.bracketParams.text.includes(' - ')) {
      this.bracketParams.text = this.bracketParams.text.replace(' - ', ' — ')
    }
    while (this.bracketParams.text.includes(' ,')) {
      this.bracketParams.text = this.bracketParams.text.replace(' ,', ',')
    }
    while (this.bracketParams.text.includes(' )')) {
      this.bracketParams.text = this.bracketParams.text.replace(' )', ')')
    }
    while (this.bracketParams.text.includes('( ')) {
      this.bracketParams.text = this.bracketParams.text.replace('( ', '(')
    }
    if (!this.bracketParams.bracketType) {
      errors.push('bracketType')
      return errors
    }
    if (this.bracketParams.bracketType === 'comma') {
      if (this.bracketParams.text.split(', ').length - 1 > 2) {
        errors.push('tooMany')
      }
      if (this.bracketParams.text.split(', ').length - 1 < 2) {
        errors.push('tooFew')
      }
    }
    if (this.bracketParams.bracketType === 'dash') {
      if (this.bracketParams.text.split(' — ').length - 1 > 2) {
        errors.push('tooMany')
      }
      if (this.bracketParams.text.split(' — ').length - 1 < 2) {
        errors.push('tooFew')
      }
    }
    if (this.bracketParams.bracketType === 'bracket') {
      if (this.bracketParams.text.split('(').length - 1 > 1) {
        errors.push('tooMany')
      } else if (this.bracketParams.text.split(')').length - 1 > 1) {
        errors.push('tooMany')
      }
      const bracketStart = this.bracketParams.text.indexOf('(')
      const bracketEnd = this.bracketParams.text.indexOf(')')
      if (bracketStart === -1 || bracketEnd === -1) {
        errors.push('tooFew')
      } else if (bracketStart > bracketEnd) {
        errors.push('order')
      }
    }
    if (this.bracketParams.bracketType === 'quote') {
      if (this.bracketParams.text.includes('"')) {
        if (this.bracketParams.text.split('"').length - 1 > 2) {
          errors.push('tooMany')
        }
        if (this.bracketParams.text.split('"').length - 1 < 2) {
          errors.push('tooFew')
        }
      } else {
        if (this.bracketParams.text.split('“').length - 1 > 1) {
          errors.push('tooMany')
        } else if (this.bracketParams.text.split('”').length - 1 > 1) {
          errors.push('tooMany')
        }
        const bracketStart = this.bracketParams.text.indexOf('“')
        const bracketEnd = this.bracketParams.text.indexOf('”')
        if (bracketStart === -1 || bracketEnd === -1) {
          errors.push('tooFew')
        } else if (bracketStart > bracketEnd) {
          errors.push('order')
        }
      }
    }
    return errors
  }

  private validatePunctuationQuestion () {
    const errors: string[] = []
    if (!this.punctuationParams.text) {
      errors.push('blank')
      return errors
    }
    this.punctuationParams.text = this.sanitisePunctuationMarks(this.punctuationParams.text)
    const caseCount = this.punctuationParams.text.split('^').length - 1
    const missingCount = this.punctuationParams.text.split('#').length - 1
    const replaceCount = this.punctuationParams.text.split('*').length - 1

    if (caseCount + missingCount + replaceCount <= 0) {
      errors.push('format')
    }

    for (let i = 0; i < this.punctuationParams.text.length; i++) {
      const c = this.punctuationParams.text[i]
      if (c === '^') {
        if (!(this.punctuationParams.text[i + 1] && this.punctuationParams.text[i + 1].match(/[A-Z]/))) {
          errors.push('case')
        }
      }
      if (c === '#') {
        if (!(this.punctuationParams.text[i + 1] && this.verifyPunctuationMark(this.punctuationParams.text[i + 1]))) {
          errors.push('missing')
        }
      }
      if (c === '*') {
        if (!(this.punctuationParams.text[i + 1] && this.punctuationParams.text[i + 2] && this.verifyPunctuationMark(this.punctuationParams.text[i + 1]) && this.verifyPunctuationMark(this.punctuationParams.text[i + 2]))) {
          errors.push('replace')
        }
      }
    }
    return errors
  }

  private validateMatchQuestion () {
    const errors: string[] = []
    if (!this.matchParams.title.value) {
      errors.push('title')
    }
    this.matchParams.title.value = this.matchParams.title.value.trim()
    if (this.matchEnableDescription && (!this.matchParams.description || (this.matchParams.description && !this.matchParams.description.value))) {
      this.matchEnableDescription = false
      this.matchParams.description = undefined
    }
    if (!this.matchEnableDescription) {
      this.matchParams.description = undefined
    }

    if (this.matchParams.description) {
      this.matchParams.description.value = this.matchParams.description.value.trim()
    }

    if (this.matchParams.items.length < 2) {
      errors.push('tooFew')
    }
    this.matchParams.items.forEach((x) => {
      x.matches = _.uniq(x.matches)
    })
    if (this.matchParams.items.filter(x => x.matches.length < 2).length) {
      errors.push('matches')
    }
    return errors
  }

  private validateSentenceQuestion () {
    const errors: string[] = []
    if (this.sentenceEnableTitle && (!this.sentenceParams.title || (this.sentenceParams.title && !this.sentenceParams.title.value))) {
      this.sentenceEnableTitle = false
      this.sentenceParams.title = undefined
    }
    if (this.sentenceEnableDescription && (!this.sentenceParams.description || (this.sentenceParams.description && !this.sentenceParams.description.value))) {
      this.sentenceEnableDescription = false
      this.sentenceParams.description = undefined
    }
    if (!this.sentenceEnableTitle) {
      this.sentenceParams.title = undefined
    }
    if (!this.sentenceEnableDescription) {
      this.sentenceParams.description = undefined
    }
    if (this.sentenceParams.title) {
      this.sentenceParams.title.value = this.sentenceParams.title.value.trim()
    }
    if (this.sentenceParams.description) {
      this.sentenceParams.description.value = this.sentenceParams.description.value.trim()
    }
    if (!this.sentenceParams.text) {
      errors.push('empty')
    }
    this.sentenceParams.text = this.sentenceParams.text.trim()
    const curlyOpenCount = this.sentenceParams.text.split('{').length - 1
    const curlyCloseCount = this.sentenceParams.text.split('}').length - 1
    const squareOpenCount = this.sentenceParams.text.split('[').length - 1
    const squareCloseCount = this.sentenceParams.text.split(']').length - 1

    if (curlyOpenCount !== curlyCloseCount) {
      errors.push('curly')
    }
    if (squareOpenCount !== squareCloseCount) {
      errors.push('square')
    }
    return errors
  }

  private closeEditModal (skipValidation: boolean = false) {
    console.log('hello?')
    if (skipValidation) { this.questionToEdit = null; return }
    if (this.questionToEdit && this.questionToEdit.id > -1 && _.isEqual(this.questionToEdit.params, this.currentQuestionParams)) { this.questionToEdit = null; return }
    this.confirmCloseEditModal()
  }

  private async confirmCloseEditModal () {
    const c = await this.confirm({ title: 'Cancel Changes?', message: 'Unsaved changes will be lost. Are you sure?' })
    if (c) {
      this.questionToEdit = null
    }
  }

  private setCorrectMultipleChoiceAnswer (id: number) {
    this.multiParams.answers.forEach((x) => {
      x.correct = false
      if (id === x.index) {
        x.correct = true
      }
    })
  }

  private tagInputTyping (e) {
    console.log(e)
  }

  private tagInputBeforeAdd (e) {
    console.log(e)
  }

  private async getLessonData () {
    try {
      const data = await Api.getGrammarCurriculumNodes('en_GB')
      if (data) {
        this.grammarStages = data
      } else {
        this.grammarStages = []
      }
      this.loading = false
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }
}
